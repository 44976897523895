<template>
  <div class="LendproductForm-container">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="submitUrl"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <el-form-item label="所属项目"  :rules="[{ required: true}]">
            <span>{{communityName}}</span>
          </el-form-item>
          <el-form-item label="电话类型" prop="numType" :rules="[{ required: true, message: '请选择电话类型', trigger: 'change' }]">
            <v-select v-model="form.numType" :options="numTypeOps"></v-select>
          </el-form-item>
          <el-form-item label="电话名称" prop="numRemark" :rules="[{ required: true, message: '请填写电话名称', trigger: 'blur' }]">
            <v-input v-model="form.numRemark" :maxlength="30"></v-input>
          </el-form-item>
          <el-form-item label="电话号码" prop="num" :rules="[{ required: true, message: '请填写电话号码', trigger: 'blur' }]">
            <v-input v-model="form.num" :maxlength="20"></v-input>
          </el-form-item>
          <el-form-item label="排序号" prop="sortNum" :rules="[{ required: true, message: '请输入排序号', trigger: 'blur'}]">
            <v-input-number v-model="form.sortNum" placeholder="请输入排序号" controls controlsPosition="right" :min="0" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import {
  getDetailUrl,
  updateUrl
} from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'
import { numTypeFormOps } from './map'

export default {
  components: {
    Col2Block,
    Col2Detail
  },
  data () {
    return {
      queryUrl: getDetailUrl,
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        submitMethod: 'post'
      },
      submitUrl: updateUrl,
      numTypeOps: numTypeFormOps,
      communityName: '',
      form: {
        numType: undefined,
        numRemark: '',
        num: '',
        sortNum: ''
      }
    }
  },
  created () {
    this.$setBreadcrumb('编辑')
  },
  mounted () {
    let { id } = this.$route.query
    id && this.$refs.form.getData({ id: id })
  },
  methods: {
    update (data) {
      console.log(data)
      this.form.id = data.id
      this.communityName = data.communityName
      this.form.communityId = data.communityId
      this.form.numType = data.numType
      this.form.numRemark = data.numRemark
      this.form.num = data.num
      this.form.sortNum = data.sortNum
    },
    submitBefore (data) {
      return true
    }
  }
}
</script>
