var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LendproductForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: {
              form: _vm.form,
              queryUrl: _vm.queryUrl,
              submitUrl: _vm.submitUrl,
              submitBefore: _vm.submitBefore,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "所属项目", rules: [{ required: true }] },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.communityName))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "电话类型",
                        prop: "numType",
                        rules: [
                          {
                            required: true,
                            message: "请选择电话类型",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.numTypeOps },
                        model: {
                          value: _vm.form.numType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "numType", $$v)
                          },
                          expression: "form.numType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "电话名称",
                        prop: "numRemark",
                        rules: [
                          {
                            required: true,
                            message: "请填写电话名称",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { maxlength: 30 },
                        model: {
                          value: _vm.form.numRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "numRemark", $$v)
                          },
                          expression: "form.numRemark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "电话号码",
                        prop: "num",
                        rules: [
                          {
                            required: true,
                            message: "请填写电话号码",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { maxlength: 20 },
                        model: {
                          value: _vm.form.num,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "num", $$v)
                          },
                          expression: "form.num",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "排序号",
                        prop: "sortNum",
                        rules: [
                          {
                            required: true,
                            message: "请输入排序号",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          placeholder: "请输入排序号",
                          controls: "",
                          controlsPosition: "right",
                          min: 0,
                        },
                        model: {
                          value: _vm.form.sortNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sortNum", $$v)
                          },
                          expression: "form.sortNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }